import React from 'react';
import { motion } from 'framer-motion';

import Logo from '../svg/logo.svg';

export const HomeWelcome = () => {
  return (
    <section className="bg-oslo-green text-oslo-blue w-full h-screen flex px-gutter justify-center items-center">
      <motion.div
        className="w-2/3 md:w-5/12"
        initial={{ opacity: 0, y: 200 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ duration: 0.8, ease: 'easeOut', delayChildren: 0.5, delay: 1 }}
      >
        <Logo />
      </motion.div>
    </section>
  );
};
